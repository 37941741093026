<template>
    <div class="rescue-detail-page">
        <div class="rescue-detail-title">
            <i class="el-icon-arrow-left" @click="backToList"></i>
            <span>故障救援详情</span>
        </div>
        <div class="rescue-detail-container">
            <div class="rescue-left-container">
                <div class="order-detail">
                    <div class="title">
                        <div class="line"></div>
                        <span class="title-name">工单详情</span>
                    </div>
                    <div class="content">
                        <div class="order-detail-info">
                            <div 
                                class="order-detail-info-item"
                                v-for="(item, index) in orderInfo"
                                :key="index"
                            >
                                <div class="lable">{{ item.lable }}：</div>
                                <div class="value">{{ item.value }}</div>
                            </div>
                        </div>
                        <div class="order-fault-list">
                            <el-table height="100%" :data="faultList">
                                <el-table-column label="故障类型" prop="gzlx"></el-table-column>
                                <el-table-column label="故障等级" prop="gzdj"></el-table-column>
                                <el-table-column label="产生时间" prop="cssj"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="history-fault">
                    <div class="title">
                        <div class="line"></div>
                        <span class="title-name">历史故障</span>
                    </div>
                    <div class="history-fault-content">
                        <div class="history-total-info">
                            <div class="total-info-item">
                                <span class="lable">累计故障次数：</span>
                                <span class="value">{{ historyList.length }}</span>
                            </div>
                        </div>
                        <div class="history-list">
                            <el-table height="100%" :data="historyList">
                                <el-table-column label="历史工单编号" prop="gdbh" width="180">
                                    <template slot-scope="{row}">
                                        <span class="job-order-span" :title="row.gdbh">{{ row.gdbh }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="完成时间" prop="wcsj" width="180"></el-table-column>
                                <el-table-column label="故障类型" prop="gzlx" width="120"></el-table-column>
                                <el-table-column label="故障等级" prop="gzdj"></el-table-column>
                                <el-table-column label="运维人员" prop="yyry"></el-table-column>
                                <el-table-column label="运维结果" prop="yyjg"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rescue-right-container">
                <div class="title">
                    <div class="line"></div>
                    <span class="title-name">工单状态</span>
                </div>
                <div class="order-status-container">
                    <div class="order-status-container-scroll">
                        <el-timeline>
                            <el-timeline-item 
                                v-for="(item, index) in orderStatusList"
                                :key="index"
                                class="order-active-status" 
                                color="#0096FF"
                            >
                                <span class="status-title">{{item.recordDesc}}</span>
                                <el-card class="status-detail" v-if="item.status === 40">
                                    <p>{{item.recordCreateTime}}</p>
                                    <p>{{getOrderDesc(item.recordDesc)}}</p>
                                    <div class="sceneimg-array-container">
                                        <el-popover
                                            v-for="item in sceneImgArray" 
                                            :key="item" 
                                            placement="top-start"
                                            width="400"
                                            trigger="hover"
                                        >
                                            <div class="scene-img-pre">
                                                <img style="height: 100%; width: 100%;" :src="item"/>
                                            </div>
                                            <img class="scene-img" slot="reference" :src="item"/>
                                        </el-popover>
                                    </div>
                                    <div class="scene-desc">{{ sceneDesc }}</div>
                                    <div class="order-operate" v-if="index === orderStatusList.length - 1">
                                        <FaultAgreeModal :jobCode="jobCode">
                                            <div class="ok-button">同意办结</div>
                                        </FaultAgreeModal>
                                        <TurndownModal :jobCode="jobCode">
                                            <div class="no-button">驳回</div>
                                        </TurndownModal>
                                    </div>
                                </el-card>
                                <el-card class="status-detail" v-else>
                                    <p>{{item.recordCreateTime}}</p>
                                    <p>{{getOrderDesc(item.recordDesc)}}</p>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>

<script>
import FaultAgreeModal from './components/FaultAgreeModal'
import TurndownModal from './components/TurndownModal'
import './RescueDetail.less';
import axios from '@/utils/axios'
export default {
    name: 'RescueDetail',
    components: {
        FaultAgreeModal,
        TurndownModal
    },
    data() {
        return {
            orderInfo: [
                { lable: '工单编号', value: '' },
                { lable: '工单状态', value: '' },
                { lable: '电池编号', value: '' },
                { lable: '电池类别', value: '' },
                { lable: '电池类型', value: '' },
                { lable: '所属站点', value: '' },
                { lable: '故障描述', value: '' },
                { lable: '运维人员', value: '' },
            ],
            jobCode: '',
            orderStatusList: [],
            sceneImgArray: [],
            faultList: [],
            historyList: [],
            sceneDesc: ''
        }
    },
    methods: {
        backToList() {
            this.$router.push('/fault-rescue-management')
        },
        getOrderStatus(jobCode) {
            axios.get(`/api/jobStatusRecord/getJobStatusRecordList/${jobCode}`).then(rs => {
                this.orderStatusList = rs;
            });
        },
        getOrderDesc(str) {
            let result = '';
            if (str === '待接单') {
                result = '已派单';
            } else if (str === '处理中') {
                result = '已接单';
            } else if (str === '待审核') {
                result = '已处理'
            } else if (str === '已完成') {
                result = '已审核';
            }
            return result;
        },
        getOrderDetailInfo(jobCode) {
            axios.get(`/api/rescueJob/getRescueJobDetail/${jobCode}`).then(rs => {
                const {
                    batteryCategory,
                    batteryCode,
                    batteryType,
                    faultDesc,
                    jobCode,
                    jobStatus,
                    siteName,
                    jobStatusLable,
                    operatorUserName
                } = rs;
                const orderInfo = [
                    { lable: '工单编号', value: jobCode },
                    { lable: '工作状态', value: jobStatusLable },
                    { lable: '电池编号', value: batteryCode },
                    { lable: '电池类别', value: batteryCategory },
                    { lable: '电池类型', value: batteryType },
                    { lable: '所属站点', value: siteName },
                    { lable: '故障描述', value:  faultDesc },
                    { lable: '运维人员', value: operatorUserName }
                ];
                this.orderInfo = orderInfo;
                this.gethistoryFault(batteryCode)
            });
        },
        getJobImg(jobCode) {
            axios.get(`/api/rescueScene/getRescueSceneList/${jobCode}/40`).then(rs => {
                const sceneImg = rs[0] && rs[0].sceneImg;
                const sceneDesc = rs[0] && rs[0].sceneDesc;
                let sceneImgArray = [] || sceneImg.split(',');
                if (sceneImg.indexOf(',') === -1) {
                    sceneImgArray = [sceneImg];
                }
                this.sceneImgArray = sceneImgArray;
                this.sceneDesc = sceneDesc;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        getJobFaultList(jobCode) {
            axios.post(` /api/rescueJob/getJobList003`, { jobCode }).then(rs => {
                this.faultList = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        gethistoryFault(batteryCode) {
            axios.post('/api/rescueJob/getJobList002', { batteryCode }).then(rs => {
                this.historyList = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        }
    },
    mounted() {
        this.jobCode = this.$route.params.jobCode;
        this.getOrderStatus(this.$route.params.jobCode);
        this.getOrderDetailInfo(this.$route.params.jobCode);
        this.getJobImg(this.$route.params.jobCode);
        this.getJobFaultList(this.$route.params.jobCode);
    }
}
</script>